import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject
} from '@angular/core'
import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategyOrigin,
  OverlayContainer,
  OverlayModule
} from '@angular/cdk/overlay'
import { popIn } from '../../../animations/pop-in.animation'
import { Size } from '@awork/_shared/types/size'
import { DecimalPipe, NgClass, NgIf } from '@angular/common'
import { PopupService } from './popup.service'
import { DEFAULT_POPUP_POSITION } from './positions'
import { Variant } from './variants'
import { HideReason } from './hide-reasons'

@Component({
  selector: 'aw-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  standalone: true,
  imports: [OverlayModule, NgClass, NgIf, DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [popIn]
})
export class PopupComponent implements OnChanges {
  @Input() variant: Variant = Variant.Default
  // For angular components, is necessary to either expose the component's elementRef or to use the cdkOverlayOrigin directive
  @Input() origin: ElementRef | HTMLElement | FlexibleConnectedPositionStrategyOrigin = {} as ElementRef
  @Input() position: ConnectedPosition = DEFAULT_POPUP_POSITION
  @Input() zIndex: number = 9000 // Customize the z-index of the popup component when it needs to be shown above other elements
  @Input() size: Size = Size.m
  @Input() heightAuto: boolean // True to allow the popup to grow indefinitely to fit its content
  @Input() widthType: 'block' | 'auto' = 'auto' // block: the popup will have the same width as the origin element
  // popupWidth: To be used together with widthType = 'block',
  // this is set only in cases in which the popup needs to have the same size as the origin element
  @Input() popupWidth: number = null
  @Input() popupHeight: number
  @Input() hideOnEnter = true
  @Input() hideOnEscape = true
  @Input() hasBackdrop = true
  @Input() showBackground = true

  @Output() showing: EventEmitter<void> = new EventEmitter<void>()
  @Output() hiding: EventEmitter<HideReason | unknown> = new EventEmitter<HideReason | unknown>()
  @Output() onEnter: EventEmitter<void> = new EventEmitter<void>()
  @Output() onEscape: EventEmitter<void> = new EventEmitter<void>()
  @Output() onKeyDown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>()
  @Output() onClickOutside: EventEmitter<void> = new EventEmitter<void>()

  isVisible = false

  popupService: PopupService = inject(PopupService)
  forcedRefresh = false

  constructor(
    private cdr: ChangeDetectorRef,
    private overlayContainer: OverlayContainer
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // check if the origin element becomes null after the popup has been opened
    if (changes.origin && !changes.origin.currentValue && this.isVisible) {
      this.hide()
    }

    if (changes.position || changes.widthType) {
      this.cdr.markForCheck()
    }
  }

  /**
   * Show the popup component
   */
  show(): void {
    this.isVisible = true

    this.showing.emit()

    this.cdr.markForCheck()

    this.customizeOverlayContainer()

    this.popupService.addPopup(this)
  }

  /**
   * Hide the popup component
   */
  hide(reason?: HideReason): void {
    this.isVisible = false

    this.hiding.emit(reason)

    this.cdr.markForCheck()

    this.popupService.removePopup(this)
  }

  /**
   * Shows or hides the popup component
   */
  toggle(): void {
    this.isVisible = !this.isVisible

    this.isVisible ? this.showing.emit() : this.hiding.emit()

    this.cdr.markForCheck()
  }

  /**
   * Adds necessary classes to customize the overlay container from the global styles
   * @private
   */
  private customizeOverlayContainer(): void {
    this.overlayContainer.getContainerElement().classList.add('popup-overlay-container')

    if (this.zIndex) {
      this.overlayContainer.getContainerElement().style.zIndex = this.zIndex.toString()
    }
  }

  /**
   * Handles mouseenter events which are outside the popup
   */
  handleClickOutside(): void {
    this.hide(HideReason.ClickOutside)
    this.onClickOutside.emit()
  }

  /**
   * Handles keydown events
   * @param {KeyboardEvent} event
   */
  handleKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Escape':
        this.handleEscapeEvent()
        break
      case 'Enter':
        this.handleEnterEvent()
        break
    }

    this.onKeyDown.emit(event)
  }

  /**
   * Hides the popup component when the user presses the escape key
   * @private
   */
  private handleEscapeEvent(): void {
    if (this.hideOnEscape) {
      this.hide(HideReason.Escape)
    }

    this.onEscape.emit()
  }

  /**
   * Hides the popup component when the user presses the enter key
   * @private
   */
  private handleEnterEvent(): void {
    if (this.hideOnEnter) {
      this.hide(HideReason.Enter)
    }

    this.onEnter.emit()
  }

  /**
   * Returns the popup HTML element
   * @returns {HTMLElement | undefined}
   */
  public getContainerElement(): HTMLElement | undefined {
    return this.overlayContainer.getContainerElement()?.children[0]?.children[0] as HTMLElement
  }

  /**
   * Updates the popup position (angua)
   */
  updatePopupPosition(): void {
    this.isVisible = false
    this.forcedRefresh = true
    this.cdr.markForCheck()

    setTimeout(() => {
      this.isVisible = true
      this.cdr.markForCheck()

      setTimeout(() => {
        this.forcedRefresh = false
        this.cdr.markForCheck()
      })
    })
  }
}
