interface IDocument {
  id: string
  versionId: string
  name: string
  emoji?: string
  isPrivate: boolean
  projectId: string
  createdBy: string
  createdOn: Date
  updatedBy: string
  updatedOn: Date
  deletedBy: string
  deletedOn: Date
  isExternal: boolean
  workspaceId: string
  content?: string
}

/**
 * The document model
 */
export class Document implements IDocument {
  id: string
  versionId: string
  name: string
  emoji?: string
  isPrivate: boolean
  projectId: string
  createdBy: string
  createdOn: Date
  updatedBy: string
  updatedOn: Date
  deletedBy: string
  deletedOn: Date
  isExternal: boolean
  workspaceId: string
  content?: string
  typeName = 'Document'

  constructor(document: Partial<IDocument>) {
    Object.assign(this, document)

    if (this.createdOn) {
      this.createdOn = new Date(this.createdOn)
    }

    if (this.updatedOn) {
      this.updatedOn = new Date(this.updatedOn)
    }

    if (this.deletedOn) {
      this.deletedOn = new Date(this.deletedOn)
    }
  }
}
